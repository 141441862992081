import * as three from "three";
import './style.css';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Pane } from "tweakpane";
import * as dat from 'dat.gui';

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.defaults({
  scroller: ".mainCotainer",
});


//rend
const rend = new three.WebGLRenderer({
  canvas: document.querySelector('#html_canvas'),
  useRgbm: false,
  // isAntialiased: true,
});



//   // if (e.pressKey === "rotation") {
//   //   const { x, y, z } = e.value;
//   //   modelRotat
//   // }
//   console.log(e);
// })

// const pane = new Pane();

// pane.addInput(data, "position", {
//   x: { step: 0.01 },
//   y: { step: 0.01 },
//   z: { step: 0.01 },
// })
// pane.addInput(data, "rotation", {
//   x: { min: -6.28, max: 6.28, step: 0.01 },
//   y: { min: -6.28, max: 6.28, step: 0.01 },
//   z: { min: -6.28, max: 6.28, step: 0.01 },
// })
  

const canhw = document.querySelector('#html_canvas');

rend.setSize(canhw.clientWidth, canhw.clientHeight);

//scene
const scene = new three.Scene();


//camera
const camera = new three.PerspectiveCamera(
  75,
  window.innerWidth / window.innerHeight,
  0.1,
  2000
);
camera.position.set(0, 0, 3);
// camera.lookAt(0, 0, 0);

//contsols
const controls = new OrbitControls(camera, rend.domElement);
controls.update();

// axis helper
const axes = new three.AxesHelper(3);
scene.add(axes);

//lights
const ambient = new three.AmbientLight(0xffffff, 34);
scene.add(ambient);
const dlight = new three.DirectionalLight(0xffffff, 4);
dlight.position.set(0, 2, 5);
scene.add(dlight);

//data for gui object
const data = {
  position: { x: 0, y: 0, z: 0 },
  rotation: { x: 0, y: 0, z: 0 },
}; 
//gui
const gui = new dat.GUI();

const dracloader = new DRACOLoader();
const gl_loader = new GLTFLoader();
const rg_loader = new RGBELoader();
dracloader.setDecoderPath(
  "https://www.gstatic.com/draco/versioned/decoders/1.5.6/"
);
dracloader.setDecoderConfig({ type: 'js' });
gl_loader.setDRACOLoader(dracloader);
let car;
// rg_loader.load("./asssests/MR_INT-005_WhiteNeons_NAD.hdr", function (hdr) {
//   hdr.mapping = three.EquirectangularReflectionMapping;
//   scene.environment = hdr;

  gl_loader.load("./asssests/scene.glb", function (gltf) {
    const model = gltf.scene;
    model.rotation.set(0, 1.6, 1.6)
    scene.add(model);
    // model.scale.set(4, 4, 4);
    gui.add(model.rotation, "x", -5, 5, 0.01);
    gui.add(model.rotation, "y", -5, 5, 0.01);
    gui.add(model.rotation, "z", -5, 5, 0.01);

    gui.add(model.position, "x", -5, 5, 0.01);
    gui.add(model.position, "y", -5, 5, 0.01);
    gui.add(model.position, "z", -5, 5, 0.01);

    // using gsap
    const t1 = gsap.timeline({
      onUpdate: setDirty,
    });
    t1.to(model.rotation, {
      x: 0,
      y: 0,
      z: 0,
      scrollTrigger: {
        trigger: ".first",
        start: "top top",
        end: "top top",
        scrub: 1,
        toggleActions: "restart none none none",
        immediateRender: false,
        // markers: {
        //   startColor: "red",
        //   endColor: "red",
        //   indent: 22,
        // },
      },
      onUpdate: setDirty,
    }).to(model.rotation, {
      x: 0,
      y: 1.57,
      z: 0,
      scrollTrigger: {
        trigger: ".two",
        start: "top 80%",
        end: "top 20%",
        scrub: 1,
        immediateRender: false,
        markers: {
          startColor: "blue",
          endColor: "blue",
          indent: 22,
        },
      },
    })
      .to(model.rotation, {
      x: 0.25,
      y: 1.11,
      z: -0.07,
      // duration: 3,
      scrollTrigger: {
        trigger: ".three",
        start: "top 80%",
        end: "top 20%",
        scrub: 1,
        immediateRender: false,
        markers: {
          startColor: "green",
          endColor: "green",
          indent: 22,
        },
      },
      })
      .to(model.position, {
      x: -2.34,
      y: -0.28,
      z: 0,
      // duration: 3,
      scrollTrigger: {
        trigger: ".three",
        start: "top 80%",
        end: "top 20%",
        scrub: 1,
        immediateRender: false,
        markers: {
          startColor: "green",
          endColor: "green",
          indent: 22,
        },
      },
      })
      .to(model.position, {
      x: 2.39,
      y: 0,
      z: 0,
      duration: 3,
      scrollTrigger: {
        trigger: ".four",
        start: "top 80%",
        end: "top 20%",
        scrub: 0.2,
        immediateRender: false,
        markers: {
          startColor: "green",
          endColor: "green",
          indent: 22,
        },
      },
      })
      .to(model.rotation, {
      x: 0,
      y: 0.72,
      z: 0.03,
      duration: 3,
      scrollTrigger: {
        trigger: ".four",
        start: "top 80%",
        end: "top 20%",
        scrub: 0.2,
        immediateRender: false,
        markers: {
          startColor: "green",
          endColor: "green",
          indent: 22,
        },
      },
    });

    function inform() {
      model.setDirty();
    }
  });
// });



function setDirty() {
  rend.render(scene, camera);
}

function animate() {
  // console.log(canhw.getBoundingClientRect().height);
  requestAnimationFrame(animate);
  rend.render(scene, camera);
  setDirty();
}

//resize
window.addEventListener("resize", function () {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  rend.setSize(canhw.clientWidth, canhw.clientHeight);
});

animate();
